body {
  margin: 0;
  font-family: 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
  color: #333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Improve readability on mobile devices */
@media (max-width: 600px) {
  body {
    font-size: 16px;
    line-height: 1.6;
  }
}

/* Add smooth scrolling to the entire page */
html {
  scroll-behavior: smooth;
}
